<template>
  <div>
    <CyAlert
      v-if="showLegend"
      data-cy="what-is-cr-legend"
      :title="$t('whatIsCR')"
      closeable
      @close="$toggle.showLegend">
      <span v-html="$t('legendTextBegin')"/>
      <router-link
        :to="{ name: 'credentials' }"
        class="cy-link">
        {{ $t('Credential') }}
      </router-link>
      <span v-html="$t('legendTextEnd')"/>
    </CyAlert>

    <CyDataTableYdApi
      id="cy-config-repositories-table"
      ref="cyDataTable"
      :fetch-available="[{ keyPath: 'configRepositories' }, { keyPath: 'credentials' }]"
      :headers="$static.headers"
      :link-builder="getLinkTarget"
      :bulk="hasBulkModeEnabled"
      :searchable-fields="$static.searchableFields"
      key-field="canonical">
      <template #table-cmp-header-actions="{ selected }">
        <a
          v-if="!showLegend"
          class="cy-link show-legend mr-4"
          data-cy="what-is-cr-button"
          @click="$toggle.showLegend">
          {{ $t('whatIsCR') }}
        </a>

        <CyButton
          v-if="!_.isEmpty(selected)"
          theme="error"
          icon="delete"
          @click="openDeleteModal(selected)">
          {{ $tc('deleteConfigReposBtn', selected.length, { nb: selected.length }) }}
        </CyButton>
        <CyButton
          v-else
          v-has-rights-to="'CreateConfigRepository'"
          icon="add"
          data-cy="create-config-repo-button"
          :to="{ name: 'newConfigRepository' }"
          @click.native="$gtm.trackEvent($static.gtmProjectsEvents.configRepositoriesAddConfigRepository)">
          {{ $t('addConfigRepository') }}
        </CyButton>

        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmDeleteTitle', toDelete.length, { nb: toDelete.length })"
          :action-btn-func="onDelete"
          :cancel-btn-func="() => $toggle.showDeleteModal(false)"
          :action-btn-text="$tc('deleteConfigReposBtn', toDelete.length, { nb: toDelete.length })"
          :loading="isDeleting"
          small
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length)"/>
          </p>
          <ul class="items-to-delete">
            <li
              v-for="{ canonical, name, url } of toDelete"
              :key="canonical">
              <h3>{{ name }}</h3>
              <p class="url">
                {{ url }}
              </p>
            </li>
          </ul>
          <p>{{ $t('confirmDeleteRepository') }}</p>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>{{ item.name }}</td>

        <td>
          <v-icon v-if="item.default">
            check
          </v-icon>
        </td>

        <td>
          <CyButton
            v-if="item.credential_canonical"
            :key="item.canonical"
            theme="grey"
            variant="tertiary"
            class="credential-link"
            sm
            :disabled="!canGoToCredential(item)"
            :readonly="!canGoToCredential(item)"
            :to="{
              name: 'credential',
              params: {
                credentialCanonical: item.credential_canonical,
                backRouteTo: 'configRepositories',
              },
            }"
            @click.stop>
            <CyIconCredential
              v-if="getCredential(item.credential_canonical).type"
              :type="getCredential(item.credential_canonical).type"/>
            <span class="credential-link__name">
              {{ getCredential(item.credential_canonical).name }}
            </span>
          </CyButton>
        </td>

        <td>{{ item.branch }}</td>

        <td>
          <div>
            <CyCopyBtn
              :copy-value="item.url"
              class="mr-2"
              small
              @click.prevent/>
            {{ item.url }}
          </div>
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CyCopyBtn from '@/components/copy-btn.vue'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { gtmProjectsEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyPageConfigRepositories',
  components: {
    CyDataTableYdApi,
    CyCopyBtn,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.configRepositories'), [
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    const { $docLinks } = this
    return {
      title: this.$t('routes.projectsSection'),
      description: {
        text: this.$t('description'),
        link: $docLinks.project.deploy,
      },
    }
  },
  data: () => ({
    showLegend: false,
    showDeleteModal: false,
    toDelete: [],
    isDeleting: false,
  }),
  computed: {
    ...mapState('organization', {
      configRepositories: (state) => state.available.configRepositories,
    }),
    ...mapGetters('organization', [
      'getAvailableByCanonical',
    ]),
    $static () {
      return {
        gtmProjectsEvents,
        headers: [
          {
            text: this.$t('name'),
            value: 'name',
            align: 'left',
          },
          {
            text: this.$t('untranslated.default'),
            value: 'default',
            align: 'left',
          },
          {
            text: this.$t('Credential'),
            value: 'credential_canonical',
            align: 'left',
            sort: (a, b) => {
              const aName = this.getCredential(a)?.name || ''
              const bName = this.getCredential(b)?.name || ''
              return aName.localeCompare(bName)
            },
          },
          {
            text: this.$t('forms.fieldBranch'),
            value: 'branch',
            align: 'left',
          },
          {
            text: this.$t('untranslated.URL'),
            value: 'url',
            align: 'left',
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'url',
            label: this.$t('untranslated.URL'),
          },
        ],
      }
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('DeleteConfigRepository')
      return canDelete && !_.isEmpty(this.configRepositories)
    },
  },
  methods: {
    ...mapActions('organization', [
      'BULK_DELETE',
    ]),
    getLinkTarget ({ canonical: configRepositoryCanonical } = {}) {
      return {
        name: 'configRepository',
        params: { configRepositoryCanonical },
      }
    },
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.showDeleteModal(true)
    },
    async onDelete () {
      const { toDelete } = this
      this.$toggle.isDeleting(true)
      await this.BULK_DELETE({ keyPath: 'configRepositories', toDelete })
      this.$toggle.isDeleting(false)
      this.$toggle.showDeleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    canGoToCredential ({ canonical }) {
      return _.some([
        this.$cycloid.permissions.canDisplay('ListCredentials', canonical),
        this.$cycloid.permissions.canDisplay('GetCredential', canonical),
      ])
    },
    getCredential (canonical) {
      return this.getAvailableByCanonical('credentials', canonical) || {}
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:ConfigRepositories',
        addConfigRepository: 'Add @:configRepository',
        areYouSure: 'Are you really sure you want to delete this @:configRepository? | Are you really sure you want to delete the following @:configRepositories?',
        confirmDeleteRepository: `Please note that this does not delete the associated git repositories. If you want to do that as well, you'll have to do it manually afterwards.`,
        confirmDeleteTitle: '@:forms.btnDelete @:configRepository | @:forms.btnDelete @:configRepositories',
        deleteConfigReposBtn: '@:forms.btnDelete {nb} @:configRepository | @:forms.btnDelete {nb} @:configRepositories',
        description: 'Create and manage Projects and Config Repositories.',
        legendTextBegin: 'A @:configRepository is one of your git repositories, we can use to store your Stacks configurations.<br/>You can add public and private git repositories, however for private git repositories you will need to provide the corresponding',
        legendTextEnd: 'that will grant access to choose a branch.',
        whatIsCR: `What's a @:configRepository?`,
      },
      es: {
        title: '@:ConfigRepositories',
        addConfigRepository: 'Agregar @:configRepository',
        areYouSure: '¿Está realmente seguro de que desea eliminar este @:configRepository? | ¿Está realmente seguro de que desea eliminar los siguientes @:configRepositories?',
        confirmDeleteRepository: 'Tenga en cuenta que esto no elimina los repositorios git asociados. Si quiere hacer eso también, tendrá que hacerlo manualmente después.',
        confirmDeleteTitle: '@:forms.btnDelete @:configRepository | @:forms.btnDelete @:configRepositories',
        deleteConfigReposBtn: '@:forms.btnDelete {nb} @:configRepository | @:forms.btnDelete {nb} @:configRepositories',
        description: 'Cree y administre proyectos y repositorios de configuración.',
        legendTextBegin: 'Un @:configRepository es uno de sus repositorios de git, que podemos usar para almacenar sus configuraciones de Stacks.<br/>Puede agregar repositorios de git públicos y privados, sin embargo, para los repositorios de git privados, deberá proporcionar el correspondiente',
        legendTextEnd: 'que otorgará acceso para elegir una sucursal.',
        whatIsCR: '¿Qué es un @:configRepository?',
      },
      fr: {
        title: '@:ConfigRepositories',
        addConfigRepository: 'Ajouter une @:configRepository',
        areYouSure: 'Êtes-vous vraiment sûr de vouloir supprimer ce @:configRepository ? | Êtes-vous vraiment sûr de vouloir supprimer les @:configRepositories suivants ?',
        confirmDeleteRepository: 'Veuillez noter que cela ne supprime pas le dépôt git associé. Si vous souhaitez le faire également, vous devrez le supprimer manuellement par la suite.',
        confirmDeleteTitle: '@:forms.btnDelete @:configRepository | @:forms.btnDelete @:configRepositories',
        deleteConfigReposBtn: '@:forms.btnDelete {nb} @:configRepository | @:forms.btnDelete {nb} @:configRepositories',
        description: 'Créez et gérez des projets et des référentiels de configuration.',
        legendTextBegin: `Un @:configRepository est l'un de vos référentiels git, nous pouvons l'utiliser pour stocker vos configurations Stacks.<br/>Vous pouvez ajouter des référentiels git publics et privés, mais pour les référentiels git privés, vous devrez fournir les`,
        legendTextEnd: `qui accordera l 'accès pour choisir une succursale.`,
        whatIsCR: `Qu'est-ce qu'un @:configRepository ?`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.show-legend {
  @include breakpoint("m") {
    max-width: 150px;
    margin-right: 10px;
    text-align: center;
  }
}

.url {
  color: cy-get-color("grey", "dark-2");
}

.credential-link {
  &__name {
    font-size: 14px;
    font-weight: $font-weight-default;
  }

  ::v-deep .credential-icon {
    margin-right: 8px !important;
  }
}
</style>
